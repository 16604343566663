import React from "react"

import Layout from "../components/layout"
import ProjectNav from "../components/project-nav"
import Seo from "../components/seo"
import desktop from "../images/tiny-house/desktop.png"
import twentyxten from "../images/tiny-house/20x10.png"
import screen2 from "../images/tiny-house/screen2.png"
import screen3 from "../images/tiny-house/screen3.png"
import screen5 from "../images/tiny-house/screen5.png"
import screen6 from "../images/tiny-house/screen6.png"

import moodboard from "../images/tiny-house/moodboard.png"
import assets from "../images/tiny-house/assets.png"

const screenshots = [screen2, screen3, screen5, screen6]

const SecondPage = () => (
  <Layout>
    <Seo title="Tiny House" />
    <div className="light-grey space-below">
      <ProjectNav
        className="small-space-top"
        previous="/marketing-monkeys"
        next="/ehb"
      />
      <div className="content grid grid--2 space smaller-space-top">
        <div className="col-1">
          <h2>
            Tiny House
            <br />
            Parallax Website{" "}
          </h2>
          <p className="teaser-text">
            For a class in visual storytelling a website about tiny houses with
            a parallax effect was created.
            <br />
            The task was to tell a story in a visually interesting way.
          </p>
          <p>
            <a
              href="https://jennifer.zosel.ch/thow/"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              View Website
            </a>
          </p>
        </div>
        <img className="col-1" src={desktop} alt="Tiny House Mockup" />
      </div>
    </div>
    <div className="content">
      <div className="grid space-below">
        {screenshots.map(screenshot => (
          <div key={screenshot} className="col-start-2 col-end">
            <img className="shadow" src={screenshot} alt="Screenshot" />
          </div>
        ))}
      </div>

      <div className="grid grid--2 space-below">
        <div className="col-1">
          <h3>
            Design Sprinting
            <br /> 20&thinsp;
            <span style={{ fontSize: "30px" }}>x</span>&thinsp;10 method
          </h3>
          <p>
            For the Tiny House project I used the "20&thinsp;x&thinsp;10"
            method; you make 20 sketches, but only spend ten minutes on each. In
            this setting you're forced to put your ideas on paper without having
            the time for doubt.
            <br /> The method is inspired by the book{" "}
            <a
              href="https://typografie.de/produkt/nea-machina-next-edition/"
              target="_blank"
              rel="noreferrer"
            >
              Nea Machina
            </a>{" "}
            by Thomas and Martin Poschauko.
          </p>
        </div>
        <img className="col-1 shadow" src={twentyxten} alt="Screenshot" />
        <img className="col-1 shadow" src={moodboard} alt="Moodboard" />
        <img className="col-1 shadow" src={assets} alt="Assets" />
      </div>
    </div>
    <ProjectNav
      previous="/marketing-monkeys"
      next="/ehb"
      className="space-below"
    />
  </Layout>
)

export default SecondPage
